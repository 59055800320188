
import login from './login'
import demo from './demo'
import home from './home'
import tabPage from './tabPage'
import policy from './policy'


export default {
    ...login,
    ...demo,
    ...home,
    ...tabPage,
    ...policy
}