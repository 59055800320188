import api from '@/libs/api'


export default {
  // 供需对接-合作案例
  getTabArticleList (data) {
    return api.get('/cms/articleList', data);
  },
  // 供需对接-入驻企业
  getCompanyList (data) {
    return api.get('/cms/companyList', data);
  },
  // 供需对接-入驻企业类别
  getCompanyTypeList () {
    return api.get('/cms/companyTypeList');
  },
  // 供需对接-搜索
  getSearch (data) {
    return api.get('/cms/search', data);
  },
  //工作站-工作站
  getWorkstationList (data) {
    return api.get('/cms/workstationList', data);
  },
  //孵化成果
  getAchievementsList (data) {
    return api.get('/cms/achievementsList', data);
  },
  //孵化成果-轮播图

  getPictureList (data) {
    return api.get('/cms/pictureList', data);
  },
  getMediaList (data) {
    return api.get('/cms/financialMediaList', data);
  },
  getMediaInfo (data) {
    return api.get('/cms/mediaList', data);
  },
  getServiceList () {
    return api.get('/cms/serviceList');
  },
  getServiceInfoList (data) {
    return api.get('/cms/serviceInfoList', data);
  },
  getDisplayList (data) {
    return api.get('/cms/displayList', data);
  },
  getFinancialMediaDetails (data) {
    return api.get('/cms/financialMediaDetails', data);
  },

}