

import router from '@/router/index'
export function newRouter (path, query) {
    let routeData
    if (query) {
        routeData = router.resolve({ path: path, query: query })
    } else {
        routeData = router.resolve({ path: path })
    }
    if (document.body.offsetWidth > 1201) {
        window.open(routeData.href, '_blank');
    } else {
        window.location.href = routeData.href
    }

}
