import api from '@/libs/api'


export default {
  // 党建要闻 等等
  getArticleList (data) { // http://123.57.32.196:9999/cms/articleList?pageNo=1&pageSize=2&type=4
    return api.get('/cms/articleList', data);
  },
  // 详情
  getArticleDetails (data) { // http://123.57.32.196:9999/cms/articleDetails?id=14775789492901
    return api.get('/cms/articleDetails', data);
  },
  //入住机构类别
  getCompanyTypeList (data) { //http://123.57.32.196:9999/cms/companyTypeList
    return api.get('/cms/companyTypeList', data);
  },
  // 入住机构 http://123.57.32.196:9999/cms/companyList?pageNo=1&pageSize=2&mainBusiness=职业教育
  getCompanyList (data) { //http://123.57.32.196:9999/cms/companyTypeList
    return api.get('/cms/companyList', data);
  },

  getAddServiceOrder (data) { // 园区服务-一站式大厅-人才服务-预约咨询
    return api.post('/cms/addServiceOrder', data);
  },
  getAddWorkOrder (data) { // 其他表单
    return api.post('/cms/addWorkOrder', data);
  },
  addWorkstation (data) { // 业务咨询o
    return api.post('/cms/addWorkstation', data);
  },
  tongji (data) { // 融媒体的统计
    return api.get('/tapi/tongji/tide_log', data);
  },
  addActivityJob (data) { // 园区服务-国际人才城-海创空间-活动-参加活动
    return api.post('/cms/addActivityJob', data);
  },
  cooperativeList (data) { // 园区服务-国际人才城-海创空间-活动-参加活动
    return api.get('/cms/cooperativeList', data);
  },

}