import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  routes: [
    { path: '*', redirect: '/404', hidden: true },
    { path: '/', redirect: '/index', hidden: true },
    {
      path: '/404',
      component: () => import('@/views/error')
    },
    {
      path: '/index',
      // name: 'index',
      component: () => import('@/views/index'),
      children: [
        { path: '/', name: 'index', meta: { title: '中国石家庄人力资源服务产业园门户' }, title: '默认首页', component: () => import('@/views/home/index') },
        { path: '/information', name: 'information', meta: { title: '热点资讯_中国石家庄人力资源服务产业园门户' }, title: '热点资讯', component: () => import('@/views/home/information') },

        { path: '/introductionPark', name: 'introductionPark', meta: { title: '园区简介_中国石家庄人力资源服务产业园门户' }, title: '园区介绍', component: () => import('@/views/park/introductionPark/index') },
        { path: '/activitynews', name: 'activitynews', meta: { title: '活动新闻_中国石家庄人力资源服务产业园门户' }, title: '活动新闻', component: () => import('@/views/park/activitynews/index') },
        { path: '/exhibition', name: 'exhibition', meta: { title: '公共展示_中国石家庄人力资源服务产业园门户' }, title: '公共展示', component: () => import('@/views/park/exhibition/index') },

        { path: '/parkservices', name: 'parkservices', meta: { title: '园区服务_中国石家庄人力资源服务产业园门户' }, title: '园区服务', component: () => import('@/views/parkservices/parkservices/index') },
        { path: '/onestopHall', name: 'onestopHall', meta: { title: '一站式大厅_中国石家庄人力资源服务产业园门户' }, title: '一站式大厅', component: () => import('@/views/parkservices/onestopHall/index') },
        { path: '/personnel', name: 'personnel', meta: { title: '国际人才城_中国石家庄人力资源服务产业园门户' }, title: '国际人才城', component: () => import('@/views/parkservices/personnel/index') },
        { path: '/docking', name: 'docking', meta: { title: '供需对接_中国石家庄人力资源服务产业园门户' }, title: '供需对接', component: () => import('@/views/parkservices/docking/index') },
        { path: '/workstation', name: 'workstation', meta: { title: '工作站_中国石家庄人力资源服务产业园门户' }, title: '工作站', component: () => import('@/views/parkservices/workstation/index') },
        { path: '/financial', name: 'financial', meta: { title: '融媒体_中国石家庄人力资源服务产业园门户' }, title: '融媒体', component: () => import('@/views/parkservices/financial/index') },

        { path: '/intelligence', name: 'intelligence', meta: { title: '高端才智_中国石家庄人力资源服务产业园门户' }, title: '高端才智', component: () => import('@/views/talentcity/intelligence/index') },
        { path: '/space', name: 'space', meta: { title: '海创空间_中国石家庄人力资源服务产业园门户' }, title: '海创空间', component: () => import('@/views/talentcity/space/index') },
        { path: '/train', name: 'train', meta: { title: '业培训_中国石家庄人力资源服务产业园门户' }, title: '创业培训', component: () => import('@/views/talentcity/train/index') },


        { path: '/policy', name: 'policy', meta: { title: '宏观政策资讯_中国石家庄人力资源服务产业园门户' }, title: '政策资讯', component: () => import('@/views/policyGuidelines/policy/index') },
        { path: '/investment', name: 'investment', meta: { title: '产业园招商政策_中国石家庄人力资源服务产业园门户' }, title: '招商政策', component: () => import('@/views/policyGuidelines/investment/index') },

        { path: '/process', name: 'process', meta: { title: '入驻流程_中国石家庄人力资源服务产业园门户' }, title: '入驻企业', component: () => import('@/views/enterpriseSettlement/process/index') },
        { path: '/enterprise', name: 'enterprise', meta: { title: '入驻企业_中国石家庄人力资源服务产业园门户' }, title: '入驻企业', component: () => import('@/views/enterpriseSettlement/enterprise/index') },
        { path: '/cooperation', name: 'cooperation', meta: { title: '合作企业_中国石家庄人力资源服务产业园门户' }, title: '合作企业', component: () => import('@/views/enterpriseSettlement/cooperation/index') },


        { path: '/contact', name: 'contact', meta: { title: '联系我们_中国石家庄人力资源服务产业园门户' }, title: '联系我们', component: () => import('@/views/contact/contact/index') },

        // 公用页面
        { path: '/apply', name: 'apply', title: '申请', component: () => import('@/views/components/apply') },
        { path: '/article', name: 'article', title: '文章', component: () => import('@/views/components/article') },


        { path: '/demo', name: 'demo', title: 'demo', component: () => import('@/views/demo') },


      ]
    },


  ]
})

