<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted () { // pageNo=1&pageSize=2&type=4
    // this.api.getArticleList({pageNo:1,pageSize:2,type:4}).then(res=>{
    //   console.log(res,8888899999990000)
    // })

    // const { aplus_queue } = window;
    // aplus_queue.push({
    //   action: 'aplus.sendPV',
    //   arguments: [{
    //     is_auto: false
    //   }, {
    //     param1: 111,
    //     param2: '222'
    //   }]
    // });
    // var _czc = _czc || [];
    // //绑定siteid，请用您的siteid替换下方"XXXXXXXX"部分
    // _czc.push(["_setAccount", "XXXXXXXX"]);

    window._hmt.push(["_trackEvent", "page", "into", "app页面"]);
  }
}
</script>

<style lang="scss">
#app {
  height: 100%;
  > div {
    height: 100%;
  }
}
</style>
