import Vue from 'vue'
import App from './App.vue'
import store from '@/store/index'
import router from '@/router/index'
import api from '@/api/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './style/swiper.min.css'
import './style/qietu.css'
import './assets/css/index.css'
import './style/style.css'
import './style/responsive.css'
Vue.prototype.api = api;
import { newRouter } from "@/libs/comm";
Vue.prototype.newRouter = newRouter;

import jsonJs from '@/libs/json'
Vue.prototype.jsonJs = jsonJs;

var _hmt = _hmt || [];
window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
(function () {
  var hm = document.createElement("script");
  let src = process.env.NODE_ENV == 'dev' ? '90db04dc15640cb118f85790d302cbbf' : 'fc8ec794f7a017dc80574414bc366fe5'
  hm.src = `https://hm.baidu.com/hm.js?${src}`;
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();


router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
    window._hmt.push(["_trackEvent", "page", "页面", to.meta.title]);
  }
  next()
})

Vue.config.productionTip = false
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')